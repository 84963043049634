@import "../../../styles/vars";
.write-us__logo-box {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}
.write-us__form {
  margin: 0 auto 54px;
  max-width: 310px;
}
@media screen and (min-width: 820px) {
  .write-us {
    position: relative;
    padding-top: 30px;
    & .write-us__title {
      margin-bottom: 24px;
    }
  }
  .write-us__logo-box {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    margin: 0;
    width: 401px;
    height: 400px;
    & img {
      width: 200px;
      height: 400px;

    }
    &.isOpen .write-us__logo--left {
      transform: translateX(-90%);
    }
    &.isOpen .write-us__logo--right {
      transform: translateX(90%);
    }
    .write-us__logo--left, .write-us__logo--right {
      transition: transform 1s ease-in-out;
      background-color: $bg-color;
    }
  }
  .write-us__form {
    margin-top: 24px;
    margin-bottom: 62px;
    max-width: 310px;
  }
}
@media screen and (min-width: 1440px) {
  .write-us {
    padding-top: 0px;
  }
  .write-us__form {
    margin-top: 48px;
    margin-bottom: 62px;
    max-width: 492px;
  }
  .write-us__logo-box {
    width: 555px;
    height: 554px;
    & img {
      width: 277px;
      height: 554px;
    }
    &.isOpen .write-us__logo--left {
      transform: translateX(-100%);
    }
    &.isOpen .write-us__logo--right {
      transform: translateX(100%);
    }
  }
}
@media screen and (min-width: 1920px) {
  .write-us {
    padding-top: 36px;
  }
  .write-us__form {
    margin-bottom: 102px;
    max-width: 686px;
  }
  .write-us__logo-box {
    width: 741px;
    height: 740px;
    & img {
      width: 370px;
      height: 740px;
    }
    &.isOpen .write-us__logo--left {
      transform: translateX(-105%);
    }
    &.isOpen .write-us__logo--right {
      transform: translateX(105%);
    }
  }
}