@import "../../../styles/vars";

.social__list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

@media screen and (min-width: 820px) {
  .social__list {
    gap: 24px;
    & img {
      width: 32px;
      height: 32px;
    }
  }
}

@media screen and (min-width: 1440px) {
  .social__list {
    & img {
      width: 48px;
      height: 48px;

    }
  }
}
@media screen and (min-width: 1920px) {
  .social__list img {
    width: 64px;
    height: 64px;
  }
}